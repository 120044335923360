@font-face {
  font-family: Azo-Sans;
  src: url("./Azo Sans/Rui Abreu - Azo Sans.otf");
}
@font-face {
  font-family: Azo-Sans-Black;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Black.otf");
}
@font-face {
  font-family: Azo-Sans-BlackItalic;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Black Italic.otf");
}
@font-face {
  font-family: Azo-Sans-Bold;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Bold.otf");
}
@font-face {
  font-family: Azo-Sans-BoldItalic;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Bold Italic.otf");
}
@font-face {
  font-family: Azo-Sans-Italic;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Italic.otf");
}
@font-face {
  font-family: Azo-Sans-Light;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Light.otf");
}
@font-face {
  font-family: Azo-Sans-LightItalic;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Light Italic.otf");
}
@font-face {
  font-family: Azo-Sans-Medium;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Medium.otf");
}
@font-face {
  font-family: Azo-Sans-MediumItalic;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Medium Italic.otf");
}
@font-face {
  font-family: Azo-Sans-Thin;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Thin.otf");
}
@font-face {
  font-family: Azo-Sans-ThinItalic;
  src: url("./Azo Sans/Rui Abreu - Azo Sans Thin Italic.otf");
}
