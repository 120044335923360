.logs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.colorDiv {
  height: 25px;
  border-radius: 10px;
  cursor: pointer;
}
.chartHeader {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.chartHeader button {
  margin: 0 5px;
}
.chartLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.viewErrorButton {
  margin-top: 5px;
  background: #ff4b4b;
  color: white;
}
hr {
  margin: 0;
  border: #669476 1px dashed;
}
p {
  margin: 0;
}

.error-row {
  background-color: red;
  color: white;
}
