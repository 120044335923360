.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ant-table-cell {
  text-align: center !important;
}
* {
  font-family: "Azo-Sans", sans-serif !important;
}

.table-row-light {
  background-color: #ffffff !important;
}
.table-row-dark {
  background-color: #eaeaea !important;
}
th {
  background-color: #d9d9d9 !important;
}
.ant-table-cell-row-hover {
  background: rgba(2, 241, 82, 0.27) !important;
}
.ant-table-column-sort {
  border-top: 1px black dashed;
  border-bottom: 1px black dashed;
}
