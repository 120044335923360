.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*background: rebeccapurple;*/
  /*min-width: 780px;*/
}
.categories {
  display: flex;
  align-items: center;
  padding: 15px 0 5px 0;
}
.timeZone {
  margin-right: 10px;
}
.headerVendorPanel {
  width: 97%;
  /*background: red;*/
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerVendorPanel h2 {
  margin: 0 15px 0 0;
}
.categories > div {
  margin-right: 4px;
}
.categories > div > button {
  padding: 3px;
  border-color: black;
}
.addButton {
  width: 100%;
  margin: 10px;
  padding: 10px 0;
  text-align: center;
  border: 1px dashed;
  cursor: pointer;
  border-radius: 15px;
}

.newFloorOrWall {
  padding: 10px;
  text-align: center;
  border: 1px dashed;
  margin: 10px;
  cursor: pointer;
  border-radius: 15px;
}
.addButton:hover {
  background: silver;
}
.colorDiv {
  height: 25px;
  border-radius: 10px;
  cursor: pointer;
}
.headerContentForVendor{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerContentForVendor .ant-select{
  background: rebeccapurple;
  min-width: 200px;
}

.globalTable{
  margin-top: 10px;
  width: 99%;
}
.tableHeaderButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.estimatedDueField > span {
  margin-right: 7px;
}
/*//*/