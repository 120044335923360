.passwordForm {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.passwordForm h2{
  font-family: Italic-bb;
}
